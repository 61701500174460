import { debounce } from 'lodash';
import React from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Icon,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Select,
  Radio,
  Typography,
  DatePicker,
} from 'antd';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';
import { checkAccess } from '../../../../shared/access/Access';
import { permissions } from '../../../../shared/access/accessConfig';
import AvatarInput from '../../../../shared/components/new-forms/AvatarInput';
import operatorApi from '../../../../services/operatorApi';
import { Header1 } from '../../../../shared/components/headers';
import clientApi from '../../../../services/clientApi';
import routes from '../../../../routes';
import {
  clientStatuses,
  selectableClientStatuses,
  clientTypes,
  clientTiers,
  clientPlatformPackages,
  industries,
  billingSchedules,
  paymentTypes,
  paymentTypeDisplayText,
  paymentTerms,
  clientServiceOtherSecurities,
  clientServiceInvoiceRequirements,
  revenueSharingPartners,
  pricingUnitTypes,
  pilotTypes,
  countries,
  currencyCodes,
  platformFeeAmountOptions,
  platformAmountOptions,
  platformManagedServiceAmountOptions,
  selectableClientBreakDuration,
  selectableClientCaptureBankDetails,
  selectableClientContractManagement,
  selectableYesOrNo,
  staffRequestContractTypes,
  selectableClientGigContractType,
  gigContractTypes,
  serviceLevelTypes,
} from '../../../../constants';
import { humanizeText, toCamelCase } from '../../../utilities/textUtils';
import ExtensionPrefixSelector from '../../../../shared/components/forms/ExtensionPrefixSelector';

const Container = styled.div`
  max-width: 910px;
  margin: 0 auto;

  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 30px;
`;

const { Option } = Select;

const SG_DEFAULT_CREDIT_CARD_PROCESSING_RATE = 0;
const SG_DEFAULT_TAX_RATE = 8;

class ClientForm extends React.Component {
  state = {
    loading: false,
    clientType: undefined,
    currencyCode: undefined,
    salesManagers: null,
    loadingSalesManager: false,
    accountManagers: null,
    loadingAccountManager: false,
    country: undefined,
    serviceLevel: undefined,
  };

  handleAccountManagerSearch = debounce(async name => {
    this.setState({ accountManagers: [], loadingAccountManager: true });
    const managers = await this.fetchOperators(name);
    this.setState({ accountManagers: managers, loadingAccountManager: false });
  }, 800);

  handleSalesManagerSearch = debounce(async name => {
    this.setState({ salesManagers: [], loadingSalesManager: true });
    const managers = await this.fetchOperators(name);
    this.setState({ salesManagers: managers, loadingSalesManager: false });
  }, 800);

  componentDidMount() {
    const { client } = this.props;
    const currencyCode = client?.pricing?.currency ? client.pricing.currency : client?.country?.currency_code;
    this.setState({
      currencyCode,
      clientType: client?.type,
      country: client?.country?.id,
      serviceLevel: client?.service_level,
    });

    if (client && client.sales_manager) {
      this.handleSalesManagerSearch(client.sales_manager.name);
    }

    if (client && client.account_manager) {
      this.handleAccountManagerSearch(client.account_manager.name);
    }
  }

  fetchOperators = async search => {
    const response = await operatorApi.fetchOperators({ page: 1, search });
    return response.results;
  };

  handleSubmit = e => {
    // Prevent default html form submit from firing
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((errors, values) => {
      if (!errors) {
        // All fields validated successfully
        this.setState({ loading: true });
        if (this.props.client) {
          this.editClient(values);
        } else {
          this.createClient(values);
        }
      }
    });
  };

  createClient = values => {
    clientApi
      .createClient(this.getClientFieldsFromValues(values))
      .then(client => {
        notification.success({
          message: 'Created client successfully',
          description: 'You can now add managers and locations',
        });

        this.uploadLogoIfNeeded(client, values).then(() => {
          this.props.history.push(routes.clientsDetail.replace(':id', client.id));
          this.setState({ loading: false });
        });
      })
      .catch(this.handleError);
  };

  editClient = values => {
    const { client } = this.props;
    clientApi
      .editClient({
        id: client.id,
        ...this.getClientFieldsFromValues(values),
      })
      .then(result => {
        notification.success({
          message: 'Updated client successfully',
        });

        this.uploadLogoIfNeeded(result, values).then(() => {
          this.props.history.push(routes.clientsDetail.replace(':id', result.id));
          this.setState({ loading: false });
        });
      })
      .catch(this.handleError);
  };

  uploadLogoIfNeeded = (client, values) => {
    // Upload logo if a new one was provided
    if (values.logo && values.logo.length > 0 && values.logo[0].originFileObj) {
      return clientApi.uploadLogo(client.id, values.logo[0].originFileObj).catch(this.handleError);
    }

    // Otherwise just return an empty promise
    return new Promise(resolve => resolve());
  };

  getPricingUnitType = (clientType, pricingUnitType) => {
    if (clientType === clientTypes.WMP) {
      return pricingUnitTypes.FLAT_RATE;
    }
    if (clientType === clientTypes.BPO) {
      return null;
    }
    return pricingUnitType;
  };

  setCurrencyCode = value => {
    let currencyCode = value; // set currency code from currency selection

    // set currency code from country selection, value is country id
    if (Number.isInteger(value)) {
      currencyCode = Object.values(countries).filter(country => country.id === value)[0].currencyCode;
      this.props.form.setFieldsValue({ client_currency: currencyCode });
    }
    this.setState({ country: value, currencyCode });
  };

  getPricingFieldsFromValues = values => {
    // JSON structure for pricing is declared below, and stored in the backend as a JSONField
    // if these fields are planned to be use anywhere else, we must make sure they are aligned for now
    // TODO: Find a solution for handling the pricing JSONField in one place
    const platformPricingFields = {
      tier: values.client_tier,
      currency: values.client_currency,
      unit_type: pricingUnitTypes.FLAT_RATE,
      unit_cost: values.pricing_unit_cost || null,
      amount_type: values.pricing_unit_amount_type,
      amount_cost: values.pricing_unit_amount_cost || null,

      additional_unit_cost: values.pricing_additional_unit_cost || null,
      additional_amount_type: values.pricing_additional_amount_type,

      managed_service_type: values.platform_pricing_managed_service_type,
      managed_service_amount_type: values.platform_pricing_managed_service_amount_type,
      managed_service_unit_cost: values.platform_pricing_managed_service_unit_cost || null,
    };

    switch (this.state.clientType) {
      case clientTypes.WMP:
        return platformPricingFields;
      case clientTypes.MANAGED_SERVICE:
        return {
          unit_type: values.pricing_unit_type || null,
          unit_cost: values.pricing_unit_cost || null,
          platform_fee_unit_type: values.pricing_platform_fee_unit_type || null,
          platform_fee_unit_cost: values.pricing_platform_fee_unit_cost || null,
        };
      case clientTypes.BPO:
        return {
          custom_pricing_fee: values.custom_pricing_fee,
          platform_fee_unit_type: values.pricing_platform_fee_unit_type || null,
          platform_fee_unit_cost: values.pricing_platform_fee_unit_cost || null,
        };
      case clientTypes.SELF_SERVE:
        return {
          currency: this.state.currencyCode,
          unit_type: pricingUnitTypes.FLAT_RATE,
          unit_cost: values.pricing_unit_cost,
          tax_rate: values.tax_rate,
          credit_card_processing_rate: values.credit_card_processing_rate,
        };
      default:
        return null;
    }
  };

  getClientFieldsFromValues = values => {
    let pilotAmount = null;
    if (values.pilot_type === pilotTypes.FREE) {
      pilotAmount = values.pilot_amount_free;
    }
    if (values.pilot_type === pilotTypes.PAID) {
      pilotAmount = values.pilot_amount_paid;
    }
    const pricingFields = this.getPricingFieldsFromValues(values);

    return {
      // Client Information
      name: values.name,
      website: values.website,
      description: values.description,
      country: values.country,
      // Billing Details
      legal_name: values.legal_name,
      business_number: values.business_number,
      tax_registration_number: values.tax_registration_number,
      billing_contact_name: values.billing_contact_name,
      billing_contact_email: values.billing_contact_email,
      billing_contact_phone: values.billing_contact_phone ? `${values.prefix}${values.billing_contact_phone}` : '',
      billing_address: {
        street_1: values.street_1,
        street_2: values.street_2,
        zip: values.zip,
        province: values.province,
        country: values.country,
      },
      // Account Information
      industry: values.industry || null,
      status: values.status,
      account_comments: values.account_comments || null,
      // Pilot details
      pilot_type: values.pilot_type || null,
      pilot_amount: pilotAmount,
      pilot_start_date: values.pilot_start_date || null,
      pilot_expiry_date: values.pilot_expiry_date || null,
      // Contract details
      contract_start_date: values.contract_start_date || null,
      contract_expiry_date: values.contract_expiry_date || null,
      // Service Information
      type: values.client_type,
      // Pricing details
      pricing: pricingFields,
      payroll_fee: values.payroll_fee || null,
      revenue_sharing: values.revenue_sharing || false,
      revenue_sharing_partner: values.revenue_sharing ? values.revenue_sharing_partner : null,
      billing_schedule: values.billing_schedule || null,
      payment_method: values.payment_method || null,
      payment_term: values.payment_term || null,
      other_security: values.other_security || null,
      invoice_requirement: values.invoice_requirement || null,
      service_comments: values.service_comments || null,
      account_manager: values.account_manager || null,
      sales_manager: values.sales_manager || null,
      // Client settings
      contract_required: values.contract_required,
      payment_enabled: values.payment_enabled,
      dco_enabled: values.dco_enabled,
      bank_details_required: values.bank_details_required,
      break_time_required: values.break_time_required,
      thai_contract_type: this.state.country === countries.thailand.id ? values.thai_contract_type : null,
      gig_contract_type: this.state.country === countries.singapore.id ? values.gig_contract_type : null,
      service_level: values.service_level,
    };
  };

  handleError = error => {
    if (!error.response) {
      notification.error({
        message: 'An unexpected error occurred',
        duration: 0,
      });
      return;
    }

    notification.error({
      message: 'An unexpected error occurred',
      description: `${error.response.status} ${error.response.statusText} - ${JSON.stringify(
        error.response.data.detail,
      )}`,
      duration: 0,
    });
  };

  handleFileChange = e => {
    // We have this because of the Ant tutorial
    if (Array.isArray(e)) {
      return e;
    }

    if (e.fileList.length > 1) {
      e.fileList.shift();
    }

    return e && e.fileList;
  };

  handleCancel = () => {
    const {
      client,
      form: { isFieldsTouched },
    } = this.props;
    const navigateAway = () => {
      if (client) {
        this.props.history.push(routes.clientsDetail.replace(':id', client.id));
        return;
      }
      this.props.history.push(routes.clients);
    };

    if (isFieldsTouched()) {
      return Modal.confirm({
        title: 'Are you sure?',
        content: 'The changes you made have not been saved yet. Are you sure you want to leave?',
        onOk: navigateAway,
      });
    }
    navigateAway();
  };

  render() {
    const {
      clientType,
      currencyCode,
      salesManagers,
      loadingSalesManager,
      accountManagers,
      loadingAccountManager,
      country,
      serviceLevel,
    } = this.state;
    const mobilePrefixes = Object.values(countries).map(country => country.dialCode);
    // We receive a client prop if we're editing an existing client
    const { t, client, form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const canEditPricing = checkAccess(permissions.editPricingClient);
    const isPilotClient = getFieldValue('status') === clientStatuses.PILOT;
    const hasChangedPaymentMethod = getFieldValue('payment_method') !== (client?.payment_method || null);

    const mobilePrefixInitialValue =
      (client && client.billing_contact_phone && client.billing_contact_phone.slice(0, 3)) || '+65';
    const mobilePrefixSelector = ExtensionPrefixSelector(
      getFieldDecorator,
      mobilePrefixes,
      mobilePrefixInitialValue,
      'prefix',
    );

    const revenueSharingOptions = [
      { value: false, label: t('N/A') },
      { value: true, label: t('partner') },
    ];
    const thaiStaffRequestContractTypeOptions = [
      { value: staffRequestContractTypes.thailand.SSO, label: t('SSO') },
      { value: staffRequestContractTypes.thailand.WHT, label: t('WHT') },
    ];

    const pricingUnitTypeOptions = [
      { value: pricingUnitTypes.FLAT_RATE, label: currencyCode },
      { value: pricingUnitTypes.PERCENTAGE, label: '%' },
    ];
    const pricingUnitTypeSelector = getFieldDecorator('pricing_unit_type', {
      initialValue: client && client.pricing?.unit_type,
    })(
      <Select style={{ width: 70 }} disabled={!canEditPricing}>
        {pricingUnitTypeOptions.map((option, index) => (
          <Option key={index} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>,
    );
    const pricingPlatformFeeUnitTypeSelector = getFieldDecorator('pricing_platform_fee_unit_type', {
      initialValue: client && client.pricing?.platform_fee_unit_type,
    })(
      <Select style={{ width: 70 }} disabled={!canEditPricing}>
        {pricingUnitTypeOptions.map((option, index) => (
          <Option key={index} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>,
    );

    // If we're editing a client, we also need to check if there is an existing logo
    // const logo = client && [{url: client.logo}];

    const actions = (
      <Row type="flex" justify="end" gutter={16}>
        <Col>
          <Button htmlType="submit" type="primary" loading={this.state.loading}>
            <Icon type="save" theme="filled" />
            Save
          </Button>
        </Col>
        <Col>
          <Button onClick={this.handleCancel}>Cancel</Button>
        </Col>
      </Row>
    );

    return (
      <Form onSubmit={this.handleSubmit}>
        <Container>
          <Row gutter={16} type="flex" justify="space-between" align="middle">
            <Col span={12}>
              <Header1>{client ? 'Edit' : 'Create'} Client Profile</Header1>
            </Col>
            <Col span={12}>{actions}</Col>
          </Row>
          {/* Client Information Card */}
          <Card title="Client Information">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item hasFeedback label="Name">
                  {getFieldDecorator('name', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your name',
                      },
                      {
                        pattern: /^((?!;).)*$/,
                        message: 'The client name contains invalid characters.',
                      },
                    ],
                    initialValue: client && client.name,
                  })(<Input placeholder="Name" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item hasFeedback label="Website">
                  {getFieldDecorator('website', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input a website',
                      },
                      {
                        type: 'url',
                        message: 'Please input a valid URL',
                      },
                    ],
                    initialValue: client && client.website ? client.website : 'https://',
                  })(<Input placeholder="https://www.example.com" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item hasFeedback label="Client Description">
                  {getFieldDecorator('description', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input a description',
                      },
                      {
                        max: 1000,
                        message: 'Please input less than 1000 characters',
                      },
                    ],
                    initialValue: client && client.description,
                  })(<Input.TextArea rows={5} placeholder="Brief description of business and story" />)}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <Form.Item label="Client Logo">
                  {getFieldDecorator('logo', {
                    valuePropName: 'fileList',
                    getValueFromEvent: this.handleFileChange,
                    // Set the initial fileList to the existing client logo if available
                    initialValue: client && client.logo && [{ uid: '-1', name: '', status: 'done', url: client.logo }],
                  })(<AvatarInput />)}
                </Form.Item>
              </Col>
            </Row>
          </Card>
          {/* Billing Details Card */}
          <Card title="Billing Details">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item hasFeedback label="Registered business name">
                  {getFieldDecorator('legal_name', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input a business name',
                      },
                    ],
                    initialValue: client && client.legal_name,
                  })(<Input placeholder="Registered business name" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item hasFeedback label="Registered business number">
                  {getFieldDecorator('business_number', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input a business number',
                      },
                    ],
                    initialValue: client && client.business_number,
                  })(<Input placeholder="Registered business number" />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} style={{ marginBottom: 30 }}>
              <Col span={12}>
                <Form.Item hasFeedback label="Tax(VAT) registration number">
                  {getFieldDecorator('tax_registration_number', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input a tax registration number',
                      },
                    ],
                    initialValue: client && client.tax_registration_number,
                  })(<Input placeholder="Tax(VAT) registration number" />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item hasFeedback label="Billing contact name">
                  {getFieldDecorator('billing_contact_name', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input a billing contact name',
                      },
                    ],
                    initialValue: client && client.billing_contact_name,
                  })(<Input placeholder="Billing contact name" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item hasFeedback label="Billing contact email">
                  {getFieldDecorator('billing_contact_email', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input a billing contact email',
                      },
                    ],
                    initialValue: client && client.billing_contact_email,
                  })(<Input placeholder="Billing contact email" />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} style={{ marginBottom: 0 }}>
              <Col span={12}>
                <Form.Item
                  hasFeedback
                  label="Billing contact phone"
                  colon={false}
                  style={{ width: '100%', marginBottom: '48px' }}
                >
                  {getFieldDecorator('billing_contact_phone', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input a billing contact phone number',
                      },
                    ],
                    initialValue: client && client.billing_contact_phone && client.billing_contact_phone.slice(3),
                  })(<Input placeholder="Billing contact phone e.g. 81313272766" addonBefore={mobilePrefixSelector} />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item hasFeedback label="Street 1">
                  {getFieldDecorator('street_1', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input a street',
                      },
                    ],
                    initialValue: client && client.billing_address && client.billing_address.street_1,
                  })(<Input placeholder="Street 1" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item hasFeedback label="Street 2">
                  {getFieldDecorator('street_2', {
                    initialValue: client && client.billing_address && client.billing_address.street_2,
                  })(<Input placeholder="Street 2" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item hasFeedback label="Zip">
                  {getFieldDecorator('zip', {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter a zip',
                      },
                    ],
                    initialValue: client && client.billing_address && client.billing_address.zip,
                  })(<Input placeholder="Zip" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item hasFeedback label="Province">
                  {getFieldDecorator('province', {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter a province',
                      },
                    ],
                    initialValue: client && client.billing_address && client.billing_address.province,
                  })(<Input placeholder="Province" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item hasFeedback label="Country">
                  {getFieldDecorator('country', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select a country',
                      },
                    ],
                    initialValue: client && client.country.id,
                  })(
                    <Select placeholder="Select country" onChange={this.setCurrencyCode}>
                      {Object.values(countries).map(option => (
                        <Select.Option key={option.id} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Card>
          {/* Service Information Card */}
          <Card title={t('serviceInformation')}>
            {/* Client type */}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item required label={t('productType')} style={{ width: '100%' }}>
                  <Form.Item htmlFor="client_type" style={{ display: 'inline-block', width: '100%', marginRight: 12 }}>
                    {getFieldDecorator('client_type', {
                      rules: [
                        {
                          required: true,
                          message: 'Please select a product type',
                        },
                      ],
                      initialValue: clientType,
                    })(
                      <Select
                        onChange={value => this.setState({ clientType: value })}
                        placeholder={t('selectProductType')}
                      >
                        {Object.keys(clientTypes).map(type => (
                          <Option key={type} value={clientTypes[type]}>
                            {t(toCamelCase(type)) /* return translated version */}
                          </Option>
                        ))}
                      </Select>,
                    )}
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  required
                  label="Service level"
                  htmlFor="service_level"
                  style={{ display: 'inline-block', width: '100%', marginRight: 12 }}
                >
                  {getFieldDecorator('service_level', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select a service level',
                      },
                    ],
                    initialValue: serviceLevel,
                  })(
                    <Select
                      onChange={value => this.setState({ serviceLevel: value })}
                      placeholder={t('selectProductType')}
                    >
                      {Object.keys(serviceLevelTypes).map(type => (
                        <Option key={type} value={serviceLevelTypes[type]}>
                          {humanizeText(serviceLevelTypes[type])}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            {clientType === clientTypes.WMP && (
              <Form.Item style={{ display: 'inline-block', width: '25%' }}>
                {getFieldDecorator('client_tier', {
                  rules: [],
                  initialValue: (client && client.pricing?.tier) || clientTiers.tier1,
                })(
                  <Select>
                    {Object.entries(clientTiers).map(([tier, value]) => (
                      <Option key={tier} value={value}>
                        {humanizeText(tier)}
                      </Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            )}

            {clientType === clientTypes.WMP && (
              <>
                <Form.Item style={{ display: 'inline-block', width: 'calc(25% - 12px)', marginRight: 12 }}>
                  {getFieldDecorator('client_tier', {
                    rules: [],
                    initialValue: (client && client.pricing?.tier) || clientTiers.noTier,
                  })(
                    <Select>
                      {Object.entries(clientTiers).map(([tier, value]) => (
                        <Option key={tier} value={value}>
                          {humanizeText(tier)}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item style={{ display: 'inline-block', width: '25%' }}>
                  {getFieldDecorator('client_platform_package', {
                    rules: [],
                    initialValue: (client && client.pricing?.package) || clientPlatformPackages.ONE,
                  })(
                    <Select>
                      {Object.entries(clientPlatformPackages).map(([key, value]) => (
                        <Option key={key} value={value}>
                          {`${value} month(s)`}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </>
            )}

            {/* Pricing */}
            <Row gutter={16}>
              <Col span={24}>
                <Typography.Text strong>{t('Pricing')}</Typography.Text>
              </Col>
            </Row>
            {/* Platform Fee */}
            {clientType === clientTypes.WMP && (
              <>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label={t('platformFee')} style={{ marginBottom: 0 }}>
                      <Row>
                        <Form.Item style={{ display: 'inline-block', width: 80, marginRight: 4 }}>
                          {getFieldDecorator('client_currency', {
                            rules: [],
                            initialValue: currencyCode,
                          })(
                            <Select onChange={this.setCurrencyCode}>
                              {Object.values(currencyCodes).map(code => (
                                <Option key={code} value={code}>
                                  {humanizeText(code)}
                                </Option>
                              ))}
                            </Select>,
                          )}
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 120, marginRight: 8, marginBottom: 0 }}>
                          {getFieldDecorator('pricing_unit_cost', {
                            rules: [],
                            initialValue: (client && client.pricing?.unit_cost) || undefined,
                          })(
                            <InputNumber
                              disabled={!canEditPricing}
                              style={{ width: '100%' }}
                              min={0}
                              max={1000000000}
                              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/(,|\s)*/g, '')}
                            />,
                          )}
                        </Form.Item>
                        <span style={{ display: 'inline-block', marginRight: 8 }}>
                          <Typography.Text>{t('for')}</Typography.Text>
                        </span>
                        <Form.Item style={{ display: 'inline-block', width: 80, marginRight: 8, marginBottom: 0 }}>
                          {getFieldDecorator('pricing_unit_amount_cost', {
                            rules: [],
                            initialValue: (client && client.pricing?.amount_cost) || undefined,
                          })(
                            <InputNumber
                              disabled={!canEditPricing}
                              style={{ width: '100%' }}
                              min={0}
                              max={1000000}
                              placeholder="0"
                              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                            />,
                          )}
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 160 }}>
                          {getFieldDecorator('pricing_unit_amount_type', {
                            rules: [],
                            initialValue:
                              (client && client.pricing?.amount_type) || Object.keys(platformFeeAmountOptions)[0],
                          })(
                            <Select>
                              {Object.entries(platformFeeAmountOptions).map(([type, text]) => (
                                <Option key={type} value={type}>
                                  {text}
                                </Option>
                              ))}
                            </Select>,
                          )}
                        </Form.Item>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  {/* Additional Worker Cost */}
                  <Col span={24}>
                    <Form.Item label={t('additional')} style={{ marginBottom: 0 }}>
                      <Row>
                        <Form.Item style={{ display: 'inline-block', width: 160, marginRight: 8 }}>
                          {getFieldDecorator('pricing_additional_unit_cost', {
                            rules: [],
                            initialValue: (client && client.pricing?.additional_unit_cost) || undefined,
                          })(
                            <InputNumber
                              disabled={!canEditPricing}
                              style={{ width: '100%' }}
                              min={0}
                              max={1000000000}
                              formatter={value => `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                            />,
                          )}
                        </Form.Item>
                        <span style={{ display: 'inline-block', marginRight: 8 }}>
                          <Typography.Text>{t('for')}</Typography.Text>
                        </span>
                        <Form.Item style={{ display: 'inline-block', width: 160, marginRight: 8 }}>
                          {getFieldDecorator('pricing_additional_amount_type', {
                            rules: [],
                            initialValue:
                              (client && client.pricing?.additional_amount_type) ||
                              Object.keys(platformAmountOptions)[0],
                          })(
                            <Select>
                              {Object.entries(platformAmountOptions).map(([type, text]) => (
                                <Option key={type} value={type}>
                                  {text}
                                </Option>
                              ))}
                            </Select>,
                          )}
                        </Form.Item>
                        <span style={{ display: 'inline-block', marginRight: 8 }}>
                          <Typography.Text>{t('perMonth')}</Typography.Text>
                        </span>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item label={t('staffing')}>
                      <Row>
                        <Form.Item style={{ display: 'inline-block', width: 200, marginRight: 8 }}>
                          {getFieldDecorator('platform_pricing_managed_service_type', {
                            rules: [],
                            initialValue:
                              (client && client.pricing?.managed_service_type) ||
                              Object.keys(platformManagedServiceAmountOptions)[0],
                          })(
                            <Select>
                              {Object.entries(platformManagedServiceAmountOptions).map(([type, text]) => (
                                <Option key={type} value={type}>
                                  {text}
                                </Option>
                              ))}
                            </Select>,
                          )}
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 160, marginRight: 8 }}>
                          {getFieldDecorator('platform_pricing_managed_service_unit_cost', {
                            rules: [],
                            initialValue: (client && client.pricing?.managed_service_unit_cost) || undefined,
                          })(
                            <InputNumber
                              disabled={!canEditPricing}
                              style={{ width: '100%' }}
                              min={0}
                              max={1000000000}
                              formatter={value => `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                            />,
                          )}
                        </Form.Item>
                        <span style={{ display: 'inline-block', marginRight: 8 }}>
                          <Typography.Text>{t('per')}</Typography.Text>
                        </span>
                        <Form.Item style={{ display: 'inline-block', width: 160, marginRight: 8 }}>
                          {getFieldDecorator('platform_pricing_managed_service_amount_type', {
                            rules: [],
                            initialValue:
                              (client && client.pricing?.managed_service_amount_type) ||
                              Object.keys(platformAmountOptions)[0],
                          })(
                            <Select>
                              {Object.entries(platformAmountOptions).map(([type, text]) => (
                                <Option key={type} value={type}>
                                  {text}
                                </Option>
                              ))}
                            </Select>,
                          )}
                        </Form.Item>
                        <span style={{ display: 'inline-block' }}>
                          <Typography.Text>{t('perMonth')}</Typography.Text>
                        </span>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            {/* Staffing fee */}
            {clientType === clientTypes.MANAGED_SERVICE && (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={t('staffingFee')}>
                    {getFieldDecorator('pricing_unit_cost', {
                      rules: [],
                      initialValue: (client && client.pricing?.unit_cost) || undefined,
                    })(
                      <Input disabled={!canEditPricing} style={{ width: 180 }} addonBefore={pricingUnitTypeSelector} />,
                    )}
                    <Typography.Text style={{ marginLeft: 12 }}>{t('perWorkerPerMonth')}</Typography.Text>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('platformFee')}>
                    {getFieldDecorator('pricing_platform_fee_unit_cost', {
                      rules: [],
                      initialValue: (client && client.pricing?.platform_fee_unit_cost) || undefined,
                    })(
                      <Input
                        disabled={!canEditPricing}
                        style={{ width: 180 }}
                        addonBefore={pricingPlatformFeeUnitTypeSelector}
                      />,
                    )}
                    <Typography.Text style={{ marginLeft: 12 }}>{t('perWorkerPerMonth')}</Typography.Text>
                  </Form.Item>
                </Col>
              </Row>
            )}
            {/* Enterprise service fee */}
            {clientType === clientTypes.BPO && (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={t('custom')}>
                    {getFieldDecorator('custom_pricing_fee', {
                      rules: [],
                      initialValue: (client && client.pricing?.custom_pricing_fee) || undefined,
                    })(<Input disabled={!canEditPricing} style={{ width: '100%' }} />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('platformFee')}>
                    {getFieldDecorator('pricing_platform_fee_unit_cost', {
                      rules: [],
                      initialValue: (client && client.pricing?.platform_fee_unit_cost) || undefined,
                    })(
                      <Input
                        disabled={!canEditPricing}
                        style={{ width: 180 }}
                        addonBefore={pricingPlatformFeeUnitTypeSelector}
                      />,
                    )}
                    <Typography.Text style={{ marginLeft: 12 }}>{t('perWorkerPerMonth')}</Typography.Text>
                  </Form.Item>
                </Col>
              </Row>
            )}
            {clientType === clientTypes.SELF_SERVE && (
              <>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label={t('workmateFee')}>
                      {getFieldDecorator('pricing_unit_cost', {
                        rules: [],
                        initialValue: (client && client.pricing?.unit_cost) || 0,
                      })(
                        <InputNumber
                          disabled={!canEditPricing}
                          style={{ width: 180 }}
                          min={0}
                          max={1000000000}
                          formatter={value => `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                          placeholder="0"
                        />,
                      )}
                      <Typography.Text style={{ marginLeft: 12 }}>{t('perStaffPerShift')}</Typography.Text>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label={t('GST')}>
                      {getFieldDecorator('tax_rate', {
                        rules: [],
                        initialValue: (client && client.pricing?.tax_rate) || SG_DEFAULT_TAX_RATE,
                      })(
                        <InputNumber
                          disabled
                          style={{ width: 180 }}
                          min={0}
                          max={100}
                          formatter={value => `% ${value}`}
                          placeholder="0"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label={t('creditCardProcessingFee')}>
                      {getFieldDecorator('credit_card_processing_rate', {
                        rules: [],
                        initialValue:
                          (client && client.pricing?.credit_card_processing_rate) ||
                          SG_DEFAULT_CREDIT_CARD_PROCESSING_RATE,
                      })(
                        <InputNumber
                          disabled
                          style={{ width: 180 }}
                          min={0}
                          max={100}
                          formatter={value => `% ${value}`}
                          placeholder="0"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            {clientType !== clientTypes.WMP && clientType !== clientTypes.SELF_SERVE && (
              <Row gutter={16}>
                {/* Payroll fee */}
                <Col span={16}>
                  <Form.Item label={t('payrollFeeIfApplicable')}>
                    {getFieldDecorator('payroll_fee', {
                      rules: [],
                      initialValue: (client && client.payroll_fee) || undefined,
                    })(
                      <InputNumber
                        disabled={!canEditPricing}
                        style={{ width: 180 }}
                        min={0}
                        max={1000000000}
                        formatter={value => `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                        placeholder="0"
                      />,
                    )}
                    <Typography.Text style={{ marginLeft: 12 }}>{t('perWorkerPerMonth')}</Typography.Text>
                  </Form.Item>
                </Col>
              </Row>
            )}
            {/* Service comments */}
            {clientType !== clientTypes.BPO && (
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label={t('comments')}>
                    {getFieldDecorator('service_comments', {
                      rules: [],
                      initialValue: (client && client.service_comments) || undefined,
                    })(<Input.TextArea disabled={!canEditPricing} rows={2} placeholder={t('comments')} />)}
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row gutter={16}>
              {/* Revenue Sharing */}
              <Col span={12}>
                <Form.Item label={t('revenueSharing')}>
                  <Form.Item name="revenue_sharing" style={{ display: 'inline-block', width: 'calc(50%)' }}>
                    {getFieldDecorator('revenue_sharing', {
                      rules: [],
                      initialValue: client && client.revenue_sharing,
                    })(
                      <Radio.Group>
                        {revenueSharingOptions.map((option, index) => {
                          return (
                            <Radio key={index} value={option.value}>
                              {option.label}
                            </Radio>
                          );
                        })}
                      </Radio.Group>,
                    )}
                  </Form.Item>
                  <Form.Item style={{ display: 'inline-block', width: 'calc(50%)' }}>
                    {getFieldDecorator('revenue_sharing_partner', {
                      rules: [],
                      initialValue: (client && client.revenue_sharing_partner) || undefined,
                    })(
                      <Select
                        style={{ width: '130%' }}
                        disabled={getFieldValue('revenue_sharing') === false}
                        placeholder="Select partner"
                      >
                        {Object.keys(revenueSharingPartners).map(partner => (
                          <Option key={partner} value={revenueSharingPartners[partner]}>
                            {revenueSharingPartners[partner]}
                          </Option>
                        ))}
                      </Select>,
                    )}
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              {/* Billing schedule */}
              <Col span={12}>
                <Form.Item label={t('billingSchedule')}>
                  {getFieldDecorator('billing_schedule', {
                    rules: [],
                    initialValue: (client && client.billing_schedule) || null,
                  })(
                    <Select placeholder={t('selectBillingSchedule')}>
                      {Object.keys(billingSchedules).map((schedule, index) => (
                        <Option key={index + 1} value={billingSchedules[schedule]}>
                          {humanizeText(billingSchedules[schedule])}
                        </Option>
                      ))}
                      <Option key={0} value={null}>
                        {t('None')}
                      </Option>
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              {/* Payment type */}
              <Col span={12}>
                <Form.Item
                  label={t('paymentType')}
                  validateStatus={hasChangedPaymentMethod ? 'warning' : ''}
                  help={hasChangedPaymentMethod ? t('paymentTypeChangeWarning') : ''}
                >
                  {getFieldDecorator('payment_method', {
                    rules: [],
                    initialValue: (client && client.payment_method) || null,
                  })(
                    <Select placeholder={t('selectPaymentType')}>
                      {Object.keys(paymentTypes).map((type, index) => (
                        <Option key={index + 1} value={paymentTypes[type]}>
                          {paymentTypeDisplayText[paymentTypes[type]]}
                        </Option>
                      ))}
                      <Option key={0} value={null}>
                        {t('None')}
                      </Option>
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              {/* Payment terms */}
              <Col span={12}>
                <Form.Item label={t('paymentTerms')}>
                  {getFieldDecorator('payment_term', {
                    rules: [],
                    initialValue: (client && client.payment_term) || null,
                  })(
                    <Select placeholder={t('selectPaymentTerms')}>
                      {Object.keys(paymentTerms).map((term, index) => (
                        <Option key={index + 1} value={paymentTerms[term]}>
                          {humanizeText(paymentTerms[term])}
                        </Option>
                      ))}
                      <Option key={0} value={null}>
                        {t('None')}
                      </Option>
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              {/* Other securities */}
              <Col span={12}>
                <Form.Item label={t('otherSecurities')}>
                  {getFieldDecorator('other_security', {
                    rules: [],
                    initialValue: (client && client.other_security) || null,
                  })(
                    <Select placeholder={t('selectOtherSecurities')}>
                      {Object.keys(clientServiceOtherSecurities).map((security, index) => (
                        <Option key={index + 1} value={clientServiceOtherSecurities[security]}>
                          {humanizeText(clientServiceOtherSecurities[security])}
                        </Option>
                      ))}
                      <Option key={0} value={null}>
                        {t('None')}
                      </Option>
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              {/* Invoice requirements */}
              <Col span={12}>
                <Form.Item label={t('invoiceRequirement')}>
                  {getFieldDecorator('invoice_requirement', {
                    rules: [],
                    initialValue: (client && client.invoice_requirement) || null,
                  })(
                    <Select placeholder={t('selectInvoiceRequirement')}>
                      {Object.keys(clientServiceInvoiceRequirements).map((requirement, index) => (
                        <Option key={index + 1} value={clientServiceInvoiceRequirements[requirement]}>
                          {humanizeText(clientServiceInvoiceRequirements[requirement])}
                        </Option>
                      ))}
                      <Option key={0} value={null}>
                        {t('None')}
                      </Option>
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Card>
          {/* Account Information Card */}
          <Card title="Account information">
            {/* Client Indusry */}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={t('industry')}>
                  {getFieldDecorator('industry', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select an industry',
                      },
                    ],
                    initialValue: (client && client.industry) || undefined,
                  })(
                    <Select placeholder={t('selectIndustry')}>
                      {Object.keys(industries).map(industry => (
                        <Option key={industry} value={industries[industry]}>
                          {humanizeText(industries[industry])}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            {/* Client Status */}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={t('status')}>
                  {getFieldDecorator('status', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select a client status',
                      },
                    ],
                    initialValue: client && client.status,
                  })(
                    <Select placeholder={t('selectStatus')}>
                      {Object.keys(selectableClientStatuses).map(status => (
                        <Option key={status} value={selectableClientStatuses[status]}>
                          {humanizeText(selectableClientStatuses[status])}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            {/* Pilot Details */}
            {isPilotClient && (
              <>
                <Row gutter={16} style={{ marginBottom: 24 }}>
                  <Col span={12}>
                    {/* Pilot Type */}
                    <Form.Item label={t('pilotType')} style={{ marginBottom: 0 }}>
                      {getFieldDecorator('pilot_type', {
                        rules: [
                          {
                            required: true,
                            message: 'Please select a pilot type',
                          },
                        ],
                        initialValue: (client && client.pilot_type) || undefined,
                      })(
                        <Radio.Group>
                          <Row style={{ display: 'flex' }}>
                            <Radio style={{ lineHeight: '40px' }} key="free" value="free">
                              {t('free')}
                            </Radio>
                            <Form.Item>
                              {getFieldDecorator('pilot_amount_free', {
                                rules: [],
                                initialValue:
                                  (client && client.pilot_type === pilotTypes.FREE && client.pilot_amount) || undefined,
                              })(
                                <InputNumber
                                  disabled={getFieldValue('pilot_type') === pilotTypes.PAID}
                                  min={0}
                                  max={1000000}
                                  style={{ width: 200 }}
                                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                                />,
                              )}
                              <Typography.Text style={{ marginLeft: 12 }}>{t('worker(s)')}</Typography.Text>
                            </Form.Item>
                          </Row>
                          <Row style={{ display: 'flex' }}>
                            <Radio style={{ lineHeight: '40px' }} key="paid" value="paid">
                              {t('paid')}
                            </Radio>
                            {getFieldDecorator('pilot_amount_paid', {
                              rules: [],
                              initialValue:
                                (client && client.pilot_type === pilotTypes.PAID && client.pilot_amount) || undefined,
                            })(
                              <InputNumber
                                disabled={getFieldValue('pilot_type') === pilotTypes.FREE}
                                style={{ width: 200 }}
                                min={0}
                                max={1000000000}
                                formatter={value => `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                              />,
                            )}
                          </Row>
                        </Radio.Group>,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                {/* Pilot Dates */}
                <Row gutter={16}>
                  <Col span={12}>
                    <Typography.Text strong>{t('pilotDates')}</Typography.Text>
                  </Col>
                </Row>
                <Row gutter={16} style={{ marginBottom: 24 }}>
                  {/* Pilot Start Date */}
                  <Col span={12}>
                    <Form.Item label={t('startDate')} style={{ marginBottom: '8px' }}>
                      {getFieldDecorator('pilot_start_date', {
                        rules: [
                          {
                            required: true,
                            message: 'Please select a pilot start date',
                          },
                        ],
                        initialValue:
                          (client && client.pilot_start_date && moment(client.pilot_start_date)) || undefined,
                      })(
                        <DatePicker
                          style={{ width: '100%' }}
                          allowClear={false}
                          format="DD-MM-YYYY"
                          disabledDate={current => current > getFieldValue('pilot_expiry_date')}
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  {/* Pilot Expiry Date */}
                  <Col span={12}>
                    <Form.Item label={t('expiryDate')} style={{ marginBottom: '8px' }}>
                      {getFieldDecorator('pilot_expiry_date', {
                        rules: [
                          {
                            required: true,
                            message: 'Please select a pilot expiry date',
                          },
                        ],
                        initialValue:
                          (client && client.pilot_expiry_date && moment(client.pilot_expiry_date)) || undefined,
                      })(
                        <DatePicker
                          style={{ width: '100%' }}
                          allowClear={false}
                          format="DD-MM-YYYY"
                          disabledDate={current => current < getFieldValue('pilot_start_date')}
                        />,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            {/* Contract Details */}
            <Row gutter={16}>
              <Col span={12}>
                <Typography.Text strong>{t('contractDetails')}</Typography.Text>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginBottom: 12 }}>
              {/* Start / Renewal date */}
              <Col span={12}>
                <Form.Item label={t('startRenewalDate')} style={{ marginBottom: '8px' }}>
                  {getFieldDecorator('contract_start_date', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select a start date',
                      },
                    ],
                    initialValue:
                      (client && client.contract_start_date && moment(client.contract_start_date)) || undefined,
                  })(
                    <DatePicker
                      style={{ width: '100%' }}
                      allowClear={false}
                      format="DD-MM-YYYY"
                      disabledDate={current =>
                        current > getFieldValue('contract_expiry_date') || current < getFieldValue('pilot_expiry_date')
                      }
                    />,
                  )}
                </Form.Item>
              </Col>
              {/* Contract Expiry Date */}
              <Col span={12}>
                <Form.Item label={t('expiryDate')} style={{ marginBottom: '8px' }}>
                  {getFieldDecorator('contract_expiry_date', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select an expiry date',
                      },
                    ],
                    initialValue:
                      (client && client.contract_expiry_date && moment(client.contract_expiry_date)) || undefined,
                  })(
                    <DatePicker
                      style={{ width: '100%' }}
                      allowClear={false}
                      format="DD-MM-YYYY"
                      disabledDate={current => current < getFieldValue('contract_start_date')}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              {/* Other comments */}
              <Col span={24}>
                <Form.Item label={t('otherComments')}>
                  {getFieldDecorator('account_comments', {
                    rules: [],
                    initialValue: (client && client.account_comments) || undefined,
                  })(<Input.TextArea rows={2} placeholder={t('otherComments')} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Sales Manager">
                  {getFieldDecorator('sales_manager', {
                    rules: [],
                    initialValue: client && client.sales_manager ? client.sales_manager.id : undefined,
                  })(
                    <Select
                      showSearch
                      allowClear
                      loading={loadingSalesManager}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      onSearch={this.handleSalesManagerSearch}
                      filterOption={inputValue => !salesManagers.includes(inputValue)}
                      placeholder="Search by sales manager name"
                    >
                      {salesManagers &&
                        salesManagers.map(manager => <Option value={manager.id}>{manager.name}</Option>)}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Account Manager">
                  {getFieldDecorator('account_manager', {
                    rules: [],
                    initialValue: client && client.account_manager ? client.account_manager.id : undefined,
                  })(
                    <Select
                      showSearch
                      allowClear
                      loading={loadingAccountManager}
                      showArrow={false}
                      defaultActiveFirstOption={false}
                      onSearch={this.handleAccountManagerSearch}
                      filterOption={inputValue => !accountManagers.includes(inputValue)}
                      placeholder="Search by account manager name"
                    >
                      {accountManagers &&
                        accountManagers.map(manager => <Option value={manager.id}>{manager.name}</Option>)}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card title="Client settings">
            <Row gutter={16}>
              <Col span={12}>
                <Typography.Text strong>{'Contract management: '}</Typography.Text>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={'Does client want to use Workmate contract?'}>
                  {getFieldDecorator('contract_required', {
                    rules: [
                      {
                        required: true,
                      },
                    ],
                    initialValue: client?.contract_required || false,
                  })(
                    <Select>
                      {selectableClientContractManagement.map(opt => (
                        <Option key={opt.value} value={opt.value}>
                          {opt.text}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Typography.Text strong>{'Payment enable'}</Typography.Text>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={'Are we (Workmate) paying workers?'}>
                  {getFieldDecorator('payment_enabled', {
                    rules: [
                      {
                        required: true,
                      },
                    ],
                    initialValue: client?.payment_enabled || false,
                  })(
                    <Select>
                      {selectableYesOrNo.map(opt => (
                        <Option key={opt.value} value={opt.value}>
                          {opt.text}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Typography.Text strong>{'DCO: '}</Typography.Text>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="DCO enabled:">
                  {getFieldDecorator('dco_enabled', {
                    rules: [
                      {
                        required: true,
                      },
                    ],
                    initialValue: client?.dco_enabled || false,
                  })(
                    <Select>
                      {selectableYesOrNo.map(opt => (
                        <Option key={opt.value} value={opt.value}>
                          {opt.text}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Typography.Text strong>Capture Worker's bank details</Typography.Text>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={`Does client want to capture worker's bank details?`}>
                  {getFieldDecorator('bank_details_required', {
                    rules: [
                      {
                        required: true,
                      },
                    ],
                    initialValue: client?.bank_details_required ? true : false,
                  })(
                    <Select>
                      {selectableClientCaptureBankDetails.map(opt => (
                        <Option key={opt.value} value={opt.value}>
                          {opt.text}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Typography.Text strong>Break duration</Typography.Text>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={`Does client want to record worker's break duration each day?`}>
                  {getFieldDecorator('break_time_required', {
                    rules: [
                      {
                        required: true,
                      },
                    ],
                    initialValue: client?.break_time_required ? true : false,
                  })(
                    <Select>
                      {selectableClientBreakDuration.map(opt => (
                        <Option key={opt.value} value={opt.value}>
                          {opt.text}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            {country === countries.singapore.id && (
              <>
                <Row gutter={16}>
                  <Col span={12}>
                    <Typography.Text strong>Contract type: </Typography.Text>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Does client want to use Contract type?">
                      {getFieldDecorator('gig_contract_type', {
                        rules: [
                          {
                            required: true,
                          },
                        ],
                        initialValue: client?.gig_contract_type
                          ? client?.gig_contract_type
                          : gigContractTypes.PART_TIME,
                      })(
                        <Select>
                          {selectableClientGigContractType.map(opt => (
                            <Option key={opt.value} value={opt.value}>
                              {opt.text}
                            </Option>
                          ))}
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Card>
          {country === countries.thailand.id && (
            <Card title="Contract Settings">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="thai_contract_type"
                    label="Select the contract for short term job (less than 90 days, more than 30 days)"
                  >
                    {getFieldDecorator('thai_contract_type', {
                      rules: [
                        {
                          required: true,
                          message: 'Please select contract type',
                        },
                      ],
                      initialValue: client && client.thai_contract_type,
                    })(
                      <Radio.Group>
                        {thaiStaffRequestContractTypeOptions.map((option, index) => {
                          return (
                            <Radio key={index} value={option.value}>
                              {option.label}
                            </Radio>
                          );
                        })}
                      </Radio.Group>,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          )}
          {actions}
        </Container>
      </Form>
    );
  }
}

export default Form.create({ name: 'client_form' })(withTranslation()(ClientForm));
